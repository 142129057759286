
import { IClinic, IUser, IUserRole } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'

@Component
export default class UserDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly user: IUser | undefined;

  admin = $app.module(Admin);

  tab = 0;
  item: IUser | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.user);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }

  async reset() {
    this.reseting = true;
    try {
      this.pwd = await this.admin.$store.resetUserPassword(this.item!);
    } catch (err) {
      $app.pushError(err);
    }
    this.reseting = false;
  }

  async switchRole(role: IUserRole) {
    role.processing = true;
    try {
      if (!role.allowed) {
        await this.admin.$store.addUserRole(role);
      } else {
        await this.admin.$store.delUserRole(role);
      }
      role.allowed = !role.allowed;
    } catch (err) {
      $app.pushError(err);
    }
    role.processing = false;
  }

  async switchClinic(clinic: IClinic) {
    clinic.processing = true;
    try {
      if (!clinic.allowed) {
        await this.admin.$store.addUserClinic(clinic);
      } else {
        await this.admin.$store.delUserClinic(clinic);
      }
      clinic.allowed = !clinic.allowed;
    } catch (err) {
      $app.pushError(err);
    }
    clinic.processing = false;
  }
}
